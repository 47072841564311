<template>
  <page-container
    is-show-breadcrumbs
    style="padding-bottom: 24px"
    class="custom-el-style"
  >
    <div @click="toLink('plugCode')" class="plug_code" :class="{'disabled':company_product_version==100}">
      <span>网页自动赋码插件</span>
      <span class="subtitle">获取插件代码与安装说明</span>
      <img src="../../images/plug_code.png" alt="">
    </div>
    <div @click="toLink('website')" class="plug_code" :class="{'disabled':company_product_version==100}">
      <span>首页网页</span>
      <span class="subtitle">配置网站首页网址</span>
      <img src="../../images/website.png" alt="">
    </div>
    <div @click="toLink('deploy')" class="plug_code" :class="{'disabled':company_product_version==100}">
      <span>元素配置</span>
      <span class="subtitle">配置网页元素显示风格</span>
      <img src="../../images/deploy.png" alt="">
    </div>
    <div @click="toLink('skip')" class="plug_code">
      <span>网页标识码</span>
      <span class="subtitle">查看网站的网页标识码</span>
      <img src="../../images/look.png" alt="">
    </div>
    <div @click="toLink('message')" class="plug_code">
      <span>访客留言</span>
      <span class="subtitle">查看访客留言</span>
      <img src="../../images/message.png" alt="">
    </div>
  </page-container>
</template>
<script>
import PageContainer from "@/components/common/PageContainer.vue";
export default {
  components: {
    PageContainer
  },
  data() {
    return {
      formData: {}
    };
  },
  computed: {
    company_product_version() {
      return this.$store.state.company_product_version;
    }
  },
  methods: {
    toLink(url) {
      if (url == "skip") {
        this.$router.push(`/manage/webpage`);
      } else {
        if (this.company_product_version < 200) {
          return false;
        }
        this.$router.push(`/wabpage/site/${url}`);
      }
    }
  }
};
</script>
<style lang="less" scoped>
/deep/.el-header {
  display: flex;
  align-items: center;
}
/deep/ .el-main {
  padding: 20px !important;
  height: auto;
  display: flex;
  flex-wrap: wrap;
}
.plug_code {
  position: relative;
  width: 275px;
  height: 188px;
  border: 2px solid #ffffff;
  background: linear-gradient(180deg, #f4f6f9 0%, #fefeff 100%);
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.08);
  padding: 24px 30px;
  margin-right: 19px;
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
  color: #011539;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  &:last-child {
    margin-right: 0;
  }
  & > .subtitle {
    font-size: 14px;
    font-weight: 400;
    color: #7b7e8c;
    margin-top: 8px;
  }
  & > img {
    width: 80px;
    height: 80px;
    position: absolute;
    right: 22px;
    bottom: 9px;
  }
}
.plug_code.disabled{
  cursor: no-drop;
  background: #ffffff;
  opacity: 0.6;
  box-shadow: 0px 3px 12px rgba(0,0,0,0.08);
}
</style>
